import { GET_LOGO, GET_STATES, GET_STATE_SCHOOLS, LOGIN_USER, AUTO_LOGIN } from "../../Types/types";
import i18n from '../../../i18n';

let intialState = {
    states: [],
    stateSchools: [],
    classes: [{ label: 'Class 9', value: 9 }, { label: 'Class 10', value: 10 }]
}


export default function authReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_LOGO:
            let obj = {
                "_id": "659f878eaf9219d393cb2d91",
                "name": "Karnataka",
                "logo": "https://testservercertificates.s3.amazonaws.com/shaktiyukta_1726570413663.png",
                "images": [
                    "https://testservercertificates.s3.amazonaws.com/WhatsApp Image 2023-12-28 at 15_1703830604913.jpeg",
                    "https://testservercertificates.s3.amazonaws.com/WhatsApp Image 2023-12-28 at 15_1703830604914.jpeg"
                ],
                "language": [
                    "English",
                    "Kannada"
                ],
                "quotes": [
                    "Empowering every child to learn and succeed!",
                    "A joint initiative by the Government of Karnataka and Nettur Technical Training Foundation (NTTF)",
                    null
                ],
                "type": "state"
            }
            stateCopy.logoDetails = action.payload;
            localStorage.setItem('logo', action.payload.logo);
            localStorage.setItem('theme', JSON.stringify(action.payload.colors || { sidebarBgColor: '#333269', btnColor: '#5855d6', textColor: '#fff' }));
            const root = document.documentElement;
            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('--sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
            // stateCopy.logoDetails = obj;
            return stateCopy;
        case LOGIN_USER:
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.data.token);
            localStorage.setItem('loginEmail', action.payload.data.email);
            localStorage.setItem('name', action.payload.data.firstname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload.data));
            localStorage.setItem('role', action.payload.data.role);
            localStorage.setItem('i18nextLng', action.payload.lang ? action.payload.lang : action.payload.data.language);
            action.payload.lang ? i18n.changeLanguage(action.payload.lang) : i18n.changeLanguage(action.payload.data.language);
            stateCopy.user = action.payload.data;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        case AUTO_LOGIN:
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.data.token);
            localStorage.setItem('loginEmail', action.payload.data.email);
            localStorage.setItem('name', action.payload.data.firstname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload.data));
            localStorage.setItem('role', action.payload.data.role);
            localStorage.setItem('i18nextLng', action.payload.lang ? action.payload.lang : action.payload.data.language);
            action.payload.lang ? i18n.changeLanguage(action.payload.lang) : i18n.changeLanguage(action.payload.data.language);
            stateCopy.user = action.payload?.data;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        case GET_STATES:
            stateCopy.states = action.payload.map(el => [{ value: el._id, label: el.name }][0]);
            return stateCopy;
        case GET_STATE_SCHOOLS:
            stateCopy.stateSchools = action.payload.map(el => [{ value: el._id, label: el.name, affiliation_id: el.affiliation_id }][0]).sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return stateCopy;
        default:
            return stateCopy;
    }
}


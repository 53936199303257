
import { GET_LOGO, GET_STATES, GET_STATE_SCHOOLS, LOGIN_USER, AUTO_LOGIN } from '../../Types/types';
import { getApiCall, postApiCall } from '../../../utils/request';
import { returnErrors } from './errorAction';


export const getLogo = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/logo`);
        localStorage.setItem('logo', response.data.data.logo);
        localStorage.setItem('state_id', response.data.data?.state_id?._id);
        return dispatch({ type: GET_LOGO, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getStates = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/states`);
        dispatch({ type: GET_STATES, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSchools = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/states/schools?_id=${id}`);
        dispatch({ type: GET_STATE_SCHOOLS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const userSignup = (obj) => async (dispatch) => {
    const response = await postApiCall(`student/auth/signup`, obj);
    if (response.data.success) {
        // return dispatch({ type: 'SIGNUP' });
        window.location.href = response.data.url;
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}


export const facultySignup = (obj) => async (dispatch) => {
    const response = await postApiCall(`student/auth/signup/faculty`, obj);
    if (response.data.success) {
        // return dispatch({ type: 'SIGNUP' });
        window.location.href = response.data.url;
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}



export const stateLogin = (login) => async (dispatch) => {
    const response = await postApiCall(`auth/login/state`, login);
    if (response.data.success) {
        // console.log(`${response.data.redirect_uri}&lang=${localStorage.getItem('i18nextLng')}`)
        window.location.href = `${response.data.redirect_uri}&lang=${localStorage.getItem('i18nextLng')}`;
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}


export const userLogin = (login, lang) => async (dispatch) => {
    const response = await postApiCall(`auth/login`, login);
    const data = response.data.data
    if (response.data.success) {
        return dispatch({ type: LOGIN_USER, payload: { data, lang } });
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}

export const autoLogin = (code, lang) => async (dispatch) => {
    const login = { otp: code }
    const response = await postApiCall(`auth/login/auto`, login);

    if (response.data.success) {
        const data = response.data.data
        // const lang = localStorage.getItem('i18nextLng') || 'en'
        dispatch({ type: AUTO_LOGIN, payload: { data, lang } });
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}

export const acceptInvite = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`auth/accept-invite`, obj);
        if (response.data.success) {
            dispatch({ type: "Accept_Invite", payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        const response = await postApiCall(`auth/forgot-password`, { email: email });
        if (response.data.success) {
            dispatch({ type: "Forgot_Password", payload: response.data });
            returnErrors(dispatch, 'OTP is sent on your registered Email', response.status)
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status)
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetPasswords = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`auth/reset-password`, obj);
        if (response.data.success) {
            dispatch({ type: "Reset_Password", payload: response.data });
            returnErrors(dispatch, 'Password has been succesfully changed', response.status)
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status)
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}
import { replaceObjectInArray } from "../../../utils/miscellneous";
import {
    EDIT_ACCOUNT, GET_ACCOUNT_DETAILS, IS_ADD_SCHOOL, RESET_LIST, BACK_TO_ACCOUNT,
    GET_STATE_LIST, ADD_STATE, UPDATE_STATE_STATUS, UPDATE_STATE, GET_SCHOOL_LIST, ADD_SCHOOL, UPDATE_SCHOOL_STATUS, UPDATE_SCHOOL, DELETE_STATE_OR_SCHOOL,
    GET_STUDENT_LIST, GET_NEXT_STUDENT_LIST, CHANGE_STUDENT_STATUS, GET_FACULTY_LIST, ADD_USER,
    GET_COLORS, APPLY_THEME,
} from "../../Types/types";


let intialState = {
    accountDetails: {
        logo: "https://elite-logos.s3.ap-south-1.amazonaws.com/NTTF_1630820183829.png",
        name: "NTTF Schools"
    },
    states: [],
    theme: (localStorage.getItem('theme')) ? (JSON.parse(localStorage.getItem('theme')) || {}) : { sidebarBgColor: '#333269', btnColor: '#5855d6', textColor: '#fff' },
    isListLoading: false,
    facultyList: [],
    studentList: []
}


export default function accountReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ACCOUNT_DETAILS:
            stateCopy.accountDetails = {
                logo: "https://elite-logos.s3.ap-south-1.amazonaws.com/NTTF_1630820183829.png",
                name: "NTTF Schools"
            };
            return stateCopy;
        case GET_STATE_LIST:
            stateCopy.states = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_STATE:
            stateCopy.states = [...stateCopy.states, action.payload];
            return stateCopy;
        case UPDATE_STATE_STATUS:
            stateCopy.states.forEach(el => {
                if (el._id === action.payload.obj.state_id) {
                    el.active = action.payload.checked
                }
            })
            return stateCopy;
        case UPDATE_STATE:
            stateCopy.states = replaceObjectInArray(stateCopy.states, action.payload._id, action.payload);
            return stateCopy;
        case GET_SCHOOL_LIST:
            stateCopy.schools = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_SCHOOL:
            stateCopy.schools = [...stateCopy.schools, action.payload];
            return stateCopy;
        case UPDATE_SCHOOL_STATUS:
            stateCopy.schools.forEach(el => {
                if (el._id === action.payload.obj.school_id) {
                    el.active = action.payload.checked
                }
            })
            return stateCopy;
        case UPDATE_SCHOOL:
            stateCopy.schools = replaceObjectInArray(stateCopy.schools, action.payload._id, action.payload);
            return stateCopy;
        case DELETE_STATE_OR_SCHOOL:
            if (action.payload.type === 'state') {
                stateCopy.states = stateCopy.states.filter(el => el._id !== action.payload.id);
            }
            else {
                stateCopy.schools = stateCopy.schools.filter(el => el._id !== action.payload.id);
            }
            return stateCopy;
        case IS_ADD_SCHOOL:
            stateCopy.isAddSchool = true;
            return stateCopy;
        case BACK_TO_ACCOUNT:
            stateCopy.isAddSchool = false;
            return stateCopy;
        case GET_STUDENT_LIST:
            stateCopy.studentList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case GET_NEXT_STUDENT_LIST:
            stateCopy.studentList = [...stateCopy.studentList, stateCopy.studentList?.data.concat(action.payload)];
            return stateCopy;
        case CHANGE_STUDENT_STATUS:
            if (action.payload.role === 'student') {
                stateCopy.studentList?.data.forEach(el => {
                    if (el._id === action.payload.obj._id) {
                        el.active = action.payload.checked
                    }
                });
            } else {
                stateCopy.facultyList?.forEach(el => {
                    if (el._id === action.payload.obj._id) {
                        el.active = action.payload.checked
                    }
                })
            }
            return stateCopy;
        case GET_FACULTY_LIST:
            stateCopy.facultyList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_USER:
            // stateCopy.facultyList = [...stateCopy.facultyList, action.payload];
            return stateCopy;
        case EDIT_ACCOUNT:
            stateCopy.successMessage = true;
            stateCopy.successMessageText = 'Campus details updated';
            return stateCopy;
        case RESET_LIST:
            stateCopy.isListLoading = true;
            return stateCopy;
        case GET_COLORS:
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            const root = document.documentElement;
            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('--sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
            return stateCopy;
        case APPLY_THEME:
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            return stateCopy;
        default:
            return stateCopy;
    }
}


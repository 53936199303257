import React from 'react';

import Profile from '../Components/Common/Profile/profile';
import Account from '../Components/Admin/Account/account';
import ManageContent from '../Components/Admin/Manage-Content/manageContent';
import ManageTopic from '../Components/Admin/Manage-Content/Manage-Topic/manageTopic';
import ManageChapter from '../Components/Admin/Manage-Content/Manage-Chapter/manageChapter';
// import ManageSubTopic from '../Components/Admin/Manage-Content/Manage-Subtopic/manageSubtopic';
import ManageResource from '../Components/Admin/Manage-Content/Manage-Resources/manageResource';
import Chapters from '../Components/User/My-Learning/Chapters/chapters';
import ManageSubject from '../Components/Admin/Manage-Content/Manage-Subject/manageSubject';
import Topics from '../Components/User/My-Learning/Topics/topics';
import AddResources from '../Components/Admin/Manage-Content/Manage-Add-Content/addResources';
import Counselling from '../Components/User/Counselling/counselling';
import SkillPods from '../Components/User/Content-Library/Skill-pods/skillPod';
import PodTopics from '../Components/User/Content-Library/PodResources/podResource';
import Skills from '../Components/User/Content-Library/Skills/skills';
import MentorConnect from '../Components/Common/Mentor-connect/mentorConnect';
import AddQuery from '../Components/Common/Mentor-connect/addQuery';
import CareerAfter from '../Components/User/Career-Path/careerAfter';
// import TestReport from '../Components/User/Counselling/test-report';
import CareerAssessment from '../Components/User/Career-assessment/careerAssessment';
import Certificate from '../utils/certificate';
import Reports from '../Components/Admin/Reports/reports';

const MyLearning = React.lazy(() => import('../Components/User/My-Learning/myLearning'));
const ContentLibrary = React.lazy(() => import('../Components/User/Content-Library/contentLibrary'));
const PracticeTest = React.lazy(() => import('../Components/User/Practice-Test/practiceTest'));
const CareerPath = React.lazy(() => import('../Components/User/Career-Path/careerPath'));


const RouteItems = [
    { path: '/my-learning', component: <MyLearning />, layout: 'mainLayout' },
    { path: '/my-learning/chapters', component: <Chapters />, layout: 'mainLayout' },
    { path: '/my-learning/topics', component: <Topics />, layout: 'mainLayout' },
    { path: '/profile', component: <Profile />, layout: 'mainLayout' },
    { path: '/content-library', component: <ContentLibrary />, layout: 'mainLayout' },
    { path: '/content-library/chapters', component: <SkillPods />, layout: 'mainLayout' },
    { path: '/content-library/skills', component: <Skills />, layout: 'mainLayout' },
    { path: '/content-library/topics', component: <PodTopics />, layout: 'mainLayout' },
    { path: '/counselling', component: <Counselling />, layout: 'mainLayout' },
    { path: '/career-assessment', component: <CareerAssessment />, layout: 'mainLayout' },
    // { path: '/test-report', component: <TestReport />, layout: 'mainLayout' },
    { path: '/practice-tests', component: <PracticeTest />, layout: 'mainLayout' },
    { path: '/career-path', component: <CareerPath />, layout: 'mainLayout' },
    { path: '/career-path/:after', component: <CareerAfter />, layout: 'mainLayout' },
    { path: '/mentor-connect', component: <MentorConnect />, layout: 'mainLayout' },
    { path: '/mentor-connect/query', component: <AddQuery />, layout: 'mainLayout' },

    { path: '/account', component: <Account />, layout: 'mainLayout' },
    { path: '/manage-content', component: <ManageContent />, layout: 'mainLayout' },
    { path: '/manage-content/subject', component: <ManageSubject />, layout: 'mainLayout' },
    { path: '/manage-content/chapter', component: <ManageChapter />, layout: 'mainLayout' },
    { path: '/manage-content/topic', component: <ManageTopic />, layout: 'mainLayout' },
    // { path: '/manage-content/subtopic', component: <ManageSubTopic />, layout: 'mainLayout' },
    { path: '/manage-content/topic/resources', component: <ManageResource />, layout: 'mainLayout' },
    { path: '/manage-content/topic/add-resources', component: <AddResources />, layout: 'mainLayout' },
    { path: '/certificate', component: <Certificate />, layout: 'mainLayout' },
    { path: '/reports', component: <Reports />, layout: 'mainLayout' },



]

export default RouteItems
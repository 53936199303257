/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import "../Login/login.scss";
import { BallBeat } from '../../../Loader/loader';
import Snackbar from '@mui/material/Snackbar';
import { facultySignup, getSchools, getStates, getLogo } from '../../../Redux/ActionCreators/Common/authAction';
import { clearErrors } from '../../../Redux/ActionCreators/Common/errorAction';
import { validatePhoneNumber } from '../../../utils/miscellneous';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import Select, { components } from 'react-select';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};



function FacultySignup(props) {
    const [defaultShowSignUp, setDefaultShowSignUp] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [isOtpEmailLoading, setIsOtpEmailLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [successEnable, setSuccessEnable] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const [school, setSchool] = useState('');
    const [schoolError, setSchoolError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logoDetails, stateSchools } = useSelector(state => state.auth);

    const error = useSelector(state => state.error)


    useEffect(() => {
        // document.title = window.location.pathname === '/login' && 'Digital NTTF | Login'
        // axios.get(process.env.REACT_APP_API_KEY + "campus/company/logo").then((response) => {
        //     if (response.data.success) {
        //         setLogo(response.data.logo);
        //         localStorage.setItem('logo', response.data.logo);
        //         localStorage.setItem('company', response.data.company_name);
        //     }
        // });

        if (localStorage.getItem('role') === 'student') {
            if (localStorage.getItem('loginToken') !== null) {
                (window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/signup') &&
                    navigate('/my-learning')
            }
            else {
                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/login')
            }
        }
        else {
            if (localStorage.getItem('loginToken') !== null) {
                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/manage-content')
            }
            else {
                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/login')
            }
        }

        const firstRender = async () => {
            const res = await dispatch(getLogo());
            // await dispatch(getAffliations());
            res.payload?.type === 'nttf' && await dispatch(getStates());
            res.payload?.type === 'state' && await dispatch(getSchools(res.payload?._id));
        }
        firstRender();
    }, [])



    const selectSchool = (e) => {
        setSchool(e);
        setSchoolError('');
    }


    const onFirstNameChange = (e) => {
        setFirstName(e.target.value);
        setFirstNameError('')
    }

    const onLastNameChange = (e) => {
        setLastName(e.target.value);
        setLastNameError('')
    }

    const onEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value);
    }

    const onPhoneChange = (e) => {
        if (validatePhoneNumber(e.target.value)) {
            setPhoneError('');
        } else {
            setPhoneError('Please enter valid mobile number');
        }
        setPhone(e.target.value);
    }




    const onChangePassword = (e) => {
        setPasswordError('');
        setPassword(e.target.value);
    }




    const signupUser = async () => {
        setIsLoading(true);
        setResponseStatus('')
        let emailvalue = email;
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (logoDetails?.type === 'state' && school === '') {
            setSchoolError(`School can't be blank`);
            setIsLoading(false);
            return false;
        }
        else if (!firstName) {
            setFirstNameError(`First Name can't be blank`);
            setIsLoading(false);
            return false;
        }
        else if (!lastName) {
            setLastNameError(`Last Name can't be blank`);
            setIsLoading(false);
            return false;
        }
        else if (!emailvalue) {
            setEmailError(`Email can't be blank`);
            setIsLoading(false);
            return false;
        }
        else if (!emailrex.test(String(emailvalue))) {
            setEmailError(`Email is not valid`);
            setIsLoading(false);
            return false;
        }
        else if (phoneError) {
            setIsLoading(false);
            return false;
        }
        else if (password === "") {
            setPasswordError('Please enter password');
            setIsLoading(false);
        }
        else {
            let signup = {
                "_id": school?.value,
                "firstname": firstName,
                "lastname": lastName,
                "email": email,
                "mobile": phone,
                "type": logoDetails?.type === 'school' ? "school" : logoDetails?.type === 'state' ? 'state' : null,
                "password": password,
                affiliation_id: logoDetails?.type === 'state' ? school?.affiliation_id : logoDetails?.affiliation_id,
                role: 'faculty'
            }

            const res = await dispatch(facultySignup(signup));
            if (res?.type === 'SIGNUP') {
                setSuccessEnable(true);
                setSuccessMessageText('Thank For Registering, You can login from state url');
            }
            setTimeout(() => {
                if (error.successMessage === false) {
                    setIsLoading(false);
                }
            }, 1000);
        }
    }

    const loginOnKeyPress = async (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            setIsLoading(true);
            setResponseStatus('')
            let emailvalue = email;
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (logoDetails?.type === 'state' && school === '') {
                setSchoolError(`School can't be blank`);
                setIsLoading(false);
                return false;
            }
            else if (!firstName) {
                setFirstNameError(`First Name can't be blank`);
                setIsLoading(false);
                return false;
            }
            else if (!lastName) {
                setLastNameError(`Last Name can't be blank`);
                setIsLoading(false);
                return false;
            }
            else if (!emailvalue) {
                setEmailError(`Email can't be blank`);
                setIsLoading(false);
                return false;
            }
            else if (!emailrex.test(String(emailvalue))) {
                setEmailError(`Email is not valid`);
                setIsLoading(false);
                return false;
            }
            else if (phoneError) {
                setIsLoading(false);
                return false;
            }
            else if (password === "") {
                setPasswordError('Please enter password');
                setIsLoading(false);
            }
            else {
                let signup = {
                    "_id": school?.value,
                    "firstname": firstName,
                    "lastname": lastName,
                    "email": email,
                    "mobile": phone,
                    "type": logoDetails?.type === 'school' ? "school" : logoDetails?.type === 'state' ? 'state' : null,
                    "password": password,
                    affiliation_id: logoDetails?.type === 'state' ? school?.affiliation_id : logoDetails?.affiliation_id,
                    role: 'faculty'
                }

                const res = await dispatch(facultySignup(signup));

                setTimeout(() => {
                    if (error.successMessage === false) {
                        setIsLoading(false);
                    }
                }, 1000);

            }
        }
    }



    const handleChange = otp => setOtp(otp);



    const onPasswordChange = async (e) => {
        // eslint-disable-next-line no-restricted-globals
        if (e.which === 13 || event.keyCode === 13) {
            if (newPassword === "") {
                setSuccessEnable(true);
                setSuccessMessageText("Please enter new password");
            }
            else if (confirmNewPassword === "") {
                setSuccessEnable(true);
                setSuccessMessageText("Please enter confirm password");
            }
            else if (newPassword !== confirmNewPassword) {
                setSuccessEnable(true);
                setSuccessMessageText("Password did not match");
            }
            else {
                setIsOtpEmailLoading(true);
                let obj = {
                    otp: otp,
                    password: newPassword
                }
                await props.acceptInvite(obj)
                await props.isAuthorized && localStorage.getItem('logintoken') !== null ?
                    navigate('/user-dashboard')
                    : navigate('/login')
                await setIsOtpEmailLoading(false);
            }
        }
    }

    const handleOtpCheck = async () => {
        if (newPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText("Please enter new password");
        }
        else if (confirmNewPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText("Please enter confirm password");
        }
        else if (newPassword !== confirmNewPassword) {
            setSuccessEnable(true);
            setSuccessMessageText("Password did not match");
        }

        else {
            setIsOtpEmailLoading(true);
            let obj = {
                otp: otp,
                password: newPassword
            }
            await props.acceptInvite(obj)
            props.isAuthorized && localStorage.getItem('logintoken') !== null ?
                navigate('/user-dashboard')
                : navigate('/login')

            await setIsOtpEmailLoading(false);
        }
    }


    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setSuccessMessageText('')
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={error.successMessage}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.successMessageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={error.successEnable}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {error.successMessageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successEnable}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <div className="row" style={{ height: '100vh', width: '100%', backgroundColor: '#fff', marginLeft: window.innerWidth > 767 ? -15 : 0 }} >
                <div className='col-md-7 text-center pr-0 login-banner  '>
                    <div className='login-banner-image'>
                    </div>
                    <div className='quotes'>
                        <Slider {...settings}>
                            {logoDetails?.images?.map((el, i) => {
                                return (
                                    <Fragment key={el.banner}>
                                        <div className='d-flex justify-content-center login-banner-image'>
                                            <img src={el} alt='' />
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-center mt-4'>
                                            <h3 style={{ width: '65%' }}>
                                                {logoDetails?.quotes[i]}
                                            </h3>
                                            {/* <img src={el.image} alt='' className='placeholder-img' />
                                                <p className='mt-1'>
                                                    {el.author}
                                                </p>
                                                <small className='mt-1'>{el.designation}</small> */}
                                        </div>
                                    </Fragment>
                                )
                            })
                            }
                        </Slider>

                    </div>

                </div>
                <div className='col-md-5  bg-light text-center' >
                    {defaultShowSignUp ?
                        <div className='login-holder' style={{ top: window.innerWidth > 767 ? '17%' : '10%' }}>
                            <Fragment>
                                <img src={logoDetails?.logo} alt='' height='70px' />
                                <div style={{ marginTop: 30, width: window.innerWidth > 767 ? '50%' : '80%' }}>
                                    {
                                        <span style={{ fontWeight: 600, color: 'red', fontSize: 14, paddingTop: '5px', position: 'relative', marginBottom: -10 }}>{responseStatus}</span>
                                    }

                                    {
                                        (logoDetails?.type === 'nttf' || logoDetails?.type === 'state') ?
                                            <div className='form-group mt-2'>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    options={stateSchools}
                                                    onChange={selectSchool}
                                                    placeholder='Select School'
                                                    value={school}
                                                />
                                                <div className='error'>{schoolError}</div>
                                            </div> :
                                            null
                                    }
                                    <div className="form-group mt-2">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback" >
                                                <img src="/images/name-icon.svg" alt='' />
                                            </span>
                                            <input type="text" className="form-control" placeholder="First Name" value={firstName} onChange={(e) => onFirstNameChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                        </div>
                                        <div className='error'>{firstNameError}</div>
                                    </div>
                                    <div className="form-group mt-2">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback" >
                                                <img src="/images/name-icon.svg" alt='' />
                                            </span>
                                            <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={(e) => onLastNameChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                        </div>
                                        <div className='error'>{lastNameError}</div>
                                    </div>
                                    <div className="form-group mt-2">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback">
                                                <img src="/images/email.svg" alt='' />
                                            </span>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e) => onEmailChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                        </div>
                                        <div className='error'>{emailError}</div>
                                    </div>
                                    <div className="form-group mt-2">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback">
                                                <img src="/images/phone.png" alt='' />
                                            </span>
                                            <input type="number" className="form-control" placeholder="Mobile no." value={phone} onChange={(e) => onPhoneChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} maxLength={10} />
                                        </div>
                                        <div className='error'>{phoneError}</div>
                                    </div>
                                    <div className="form-group">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback">
                                                <img src="/images/lock.svg" alt='' />
                                            </span>
                                            <input type="password" className="form-control" aria-describedby="emailHelp" value={password} placeholder="Password" onChange={(e) => onChangePassword(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                        </div>
                                        <div className='error'>{passwordError}</div>

                                    </div>
                                    <div className='position-relative'>
                                        <button onClick={() => signupUser()} className="login-button">
                                            {isLoading ? <BallBeat
                                                color={'#fff'}
                                                loading={true}
                                            /> : "SIGNUP"}
                                        </button>

                                    </div>
                                </div>
                            </Fragment>
                        </div> :
                        <div className="login-holder otp-holder">
                            <h3>Set your password</h3>
                            {
                                <div className="otp-fields" style={{ width: window.innerWidth > 767 ? '60%' : '90%' }}>
                                    <p className='d-none'>Please enter the code that is sent to your mail.</p>
                                    {/* <div style={{ display: 'none' }}>
                                        <OtpInput
                                            numInputs={6}
                                            onChange={handleChange}
                                            inputStyle="otp-input"
                                            value={otp}
                                            separator={<span>-</span>}

                                        />
                                    </div> */}
                                    <input className='form-control d-none' onChange={(e) => handleChange(e.target.value)} style={{ height: 48 }} value={otp} />
                                    <div className="form-group mt-3">
                                        <input type="password" placeholder="Enter new password" className="form-control" aria-describedby="emailHelp" onChange={(e) => setNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                        <input type="password" placeholder="Confirm new password" className="form-control" aria-describedby="emailHelp" onChange={(e) => setConfirmNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                    </div>

                                    <button disabled={isOtpEmailLoading !== false} onClick={() => handleOtpCheck()} className="primary-button position-relative">
                                        {isOtpEmailLoading ? <BallBeat
                                            color={'#fff'}
                                            loading={true}
                                        /> : "Set"}
                                    </button>
                                </div>
                            }

                        </div>
                    }
                </div>

            </div>
        </Fragment>
    )
}


export default FacultySignup